import { NumberedRange } from "components/components/forms/range";
import { NameInput } from "components/components/forms/text";
import React, { useEffect, useState } from "react";

export default function SteamWishlistForm({ setData, defaultReward }) {

    const [steamId, setSteamId] = useState('');
    const [reward, setReward] = useState(defaultReward);
    const [minMinutes, setMinMinutes] = useState(0);

    useEffect(() => {
        setData({
            steamId, reward, minMinutes
        });
    }, [steamId, reward, minMinutes])

    return (
        <>
            <NameInput
                set={setSteamId}
                title="Game Steam ID"
                placeholder={'xxxxxxx'}
            />
            <NumberedRange
                max={60 * 10}
                min={0}
                initial={0}
                step={1}
                title="Time Played"
                unit="min"
                classes="yellow-qudo"
                setValue={setMinMinutes}
                value={minMinutes}
            />
            <NumberedRange
                max={100}
                min={0.001}
                initial={defaultReward}
                title="Reward"
                unit="QUDO"
                classes="yellow-qudo"
                setValue={setReward}
                value={reward}
            />
        </>
    );
}