import { ButtonInput } from "components/components/forms/buttons";
import { DropdownInput } from "components/components/forms/dropdowns";
import { NameInput } from "components/components/forms/text";
import { MessageModal } from "components/components/modals/modals";
import QUDOServer from "helpers/QUDOServerConnection";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import SteamWishlistForm from "./challengesForms/SteamWishlistForm";

export default function Challenge({
    game, challenge
}) {

    const [challenges, setChallenges] = useState();
    const [defaultReward, setDefaultReward] = useState(0);
    const [gameChallenges, setGameChallenges] = useState({});

    const [valid, setValid] = useState(false);
    const [form, setForm] = useState(null);
    const [formData, setFormData] = useState(null);
    const [dropdownChallenge, setDropdownChallenge] = useState([{ key: '', label: '' }]);
    const [selectedChallenge, setSelectedChallenge] = useState();
    const [modalMessage, setModalMessage] = useState('');

    useEffect(() => {
        const config = {
            withCredentials: true
        }

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/challenge/getgamedevchallenges`, { game: game }, config)
            .then(response => {
                console.log(response.data)
            });

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/challenge/getavailablechallenges`, { game: game }, config)
            .then(response => {
                console.log("avail", response.data)
                setDefaultReward(response.data.reward);
                setChallenges(response.data.challenges);

                const dropdown = response.data.challenges.map(c => {
                    return ({
                        key: c.name,
                        label: c.name
                    });
                });
                setDropdownChallenge(dropdown);
            });
    }, []);

    useEffect(() => {
        if (challenge) {

        } else if ((challenge && challenge.name === 'Steam Wishlist')
            || selectedChallenge.name === 'Steam Wishlist') {
            console.log(formData)

            if (!isNaN(formData.reward)
                && !isNaN(formData.minMinutes)
                && formData.steamId !== '') {

                setValid(true);
            } else {
                setValid(false);
            }
            //     name: string,
            //         game: IGamedevGame,
            //             reward: number,
            //                 conditions: { [Key in ConditionNames]: ICondition; },
            // totalCompleted: number,
            //     active: boolean
        } else {
            setValid(false);
        }

    }, [formData]);

    const handleChallengeSelection = (name) => {
        setSelectedChallenge(challenges.find(c => c.name === name));
    }

    const submit = (value) => {
        const body = {};

        if ((challenge && challenge.name === 'Steam Wishlist')
            || selectedChallenge.name === 'Steam Wishlist') {
            body = {
              name: selectedChallenge.name,
                //         game: IGamedevGame,
                //             reward: number,
                //                 conditions: { [Key in ConditionNames]: ICondition; },
                // totalCompleted: number,
                //     active: boolean
            }
        }

        var url = '';

        if (challenge) {
            url = `${process.env.REACT_APP_QUDO_SERVER}/game/challenge/update`;
        } else {
            url = `${process.env.REACT_APP_QUDO_SERVER}/game/challenge/setgamedevchallenge`;
        }

        console.log("submitting challenge: ", body, " to ", url, " with credentials")

        QUDOServer.post(url, body, { withCredentials: true })
            .then(result => {
                setModalMessage('Success');
                //setSuccessModal(true);

                //reset();
                //props.refresh();
            })
            .catch(error => {
                setModalMessage(error.errorMessage);
                //setFailureModal(true);

                //reset();
                //props.refresh();
            });
    }

    return (
        <div>
            {challenge ?
                <div
                    className={`text-break col-12 mb-4 edit-screen-menu`}>
                    Edit Challenges
                </div>
                :
                <>
                    <div
                        className={`text-break col-12 mb-4 edit-screen-menu`}>
                        Add Challenges
                    </div>
                    <div className="col-12">
                        <Form ref={(el) => setForm(el)}>
                            <DropdownInput
                                label='Select Available Challenges'
                                initial='Please select a challenge'
                                set={handleChallengeSelection}
                                choices={dropdownChallenge}
                            />

                            {(selectedChallenge && selectedChallenge.name === 'Steam Wishlist') &&
                                <SteamWishlistForm setData={setFormData} defaultReward={defaultReward} />
                            }
                            {/* <NameInput
                                set={setAlias}
                                title="Challenge name"
                                pattern={regexes.highscoreAlias}
                                disabled={props.highscore}
                                placeholder={props.highscore ? props.highscore.alias : "high1"}
                                invalid={!aliasValid}
                                errorMessage={'Alias is already being used!'}
                            /> */}

                            <ButtonInput
                                trigger
                                label="Create Challenge"
                                set={submit}
                                disabled={!valid}
                                style={
                                    !valid
                                        ? { cursor: 'initial' }
                                        : { backgroundColor: "#fbd731" }
                                }
                            />
                        </Form>
                    </div>
                </>
            }
            <MessageModal
                show={!!modalMessage}
                message={modalMessage}
                hide={() => {
                    setModalMessage('');
                }}
            />
        </div>
    );
}