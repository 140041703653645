import QUDOServer from "helpers/QUDOServerConnection";
import React, { useEffect, useState } from "react";

export default function ChallengesList({
    game, refresh
}) {

    const [challenges, setChallenges] = useState();
    const [gameChallenges, setGameChallenges] = useState({});

    useEffect(() => {
        const config = {
            withCredentials: true
        }

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/challenge/getgamedevchallenges`, { game: game }, config)
            .then(response => {
                console.log(response.data)
            });
    }, []);

    return (
        <div>
            <div className='col-12 mt-2 achievementtitle'>
                This game doesn't have any challenges yet!
            </div>
        </div>
    );
}